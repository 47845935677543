<template>
    <!--培训-->
    <section class="new_css">
        <el-row class="new_header">
            <el-col v-if="buttonList.length > 0" :span="24" class="organizetoolbar" style="padding-bottom: 0px">
                <el-form :inline="true" ref="selectForm" :model="selectForm" class="select_form">
                    <el-form-item label="填报人:">
                        <el-input type="text" v-model="selectForm.appuserName"></el-input>
                    </el-form-item>
                    <el-form-item label="部门:">
                        <el-input type="text" v-model="selectForm.appDpt"></el-input>
                    </el-form-item>
                    <el-form-item label="公司:">
                        <el-input type="text" v-model="selectForm.appFactory"></el-input>
                    </el-form-item>
                    <el-form-item label="培训级别:">
                        <el-select v-model="selectForm.ApplyLevel" clearable>
                            <el-option label="集团级" value="集团级"></el-option>
                            <el-option label="分公司级" value="分公司级"></el-option>
                            <el-option label="部门级 " value="部门级"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="计划时间:">
                        <!--<el-date-picker type="date" v-model="dlgFrom.TrainTime" :disabled="!optType" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>-->
                        <el-date-picker v-model="value2"
                                        type="datetimerange"
                                        unlink-panels
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        align="right">
                        </el-date-picker>
                    </el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar>
                </el-form>
            </el-col>
        </el-row>
        <div class="calc-height">
            <el-table :data="dataList" highlight-current-row height="100%" @current-change="selectCurrentRow" @row-dblclick="handleShow" class="new_table">
                <el-table-column prop="Apply_QRCode" label="培训编号"> </el-table-column>
                <el-table-column prop="UserName" label="填报人"> </el-table-column>
                <el-table-column prop="ApplyDpt" label="填报人部门"> </el-table-column>
                <el-table-column prop="ApplyFactory" label="填报人公司"> </el-table-column>
                <el-table-column prop="ProjectType" label="项目类型"> </el-table-column>
                <el-table-column prop="Apply_ProjectName" label="培训名称"> </el-table-column>
                <el-table-column prop="TrainWay" label="培训方式"> </el-table-column>
                <el-table-column prop="Lecturers" label="讲师" :formatter="formatLecturer"></el-table-column>
                <el-table-column prop="TrainTimeStart" label="计划时间" :formatter="formatStartTime"></el-table-column>
                <el-table-column prop="TrainMoney" label="预计费用"> </el-table-column>
                <el-table-column prop="Apply_IsTest" label="是否考核"> </el-table-column>
                <el-table-column prop="tag" label="培训资料" width="100">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.bIsopt1File ? 'success' : 'warning'"
                                disable-transitions>{{scope.row.bIsopt1File? `是`:`否`}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="tag" label="现场照片" width="100">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.bIsopt2File ? 'success' : 'warning'"
                                disable-transitions>{{scope.row.bIsopt2File? `是`:`否`}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="tag" label="其他资料" width="100">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.bIsopt3File ? 'success' : 'warning'"
                                disable-transitions>{{scope.row.bIsopt3File? `是`:`否`}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="120">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="showSignin(scope.$index, dataList)" type="text" size="small">签到记录</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页器 -->
        <el-pagination align="center" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize"
                       layout="prev, pager, next, jumper,total" :page-count="total" :total="totaldata">
        </el-pagination>
        <checkupModal ref="checkupModal" @callback="getData"></checkupModal>
        <siginModal ref="siginModal"></siginModal>
    </section>

</template>

<script>
    import util from "../../../util/date";
    import Toolbar from "../../components/newToolbar";
    import { getButtonList } from "../../promissionRouter";
    import checkupModal from "../componentModals/trainModal"    // 培训
    import siginModal from "../componentModals/siginModal"      // 签到
    import {
        GetTrainInfoPageList,
        DeleteTrainInfo
    } from "../../api/hr";
    export default {
        components: { Toolbar, checkupModal, siginModal },
        data() {
            return {
                selectForm: {}, // 筛选项
                currentRow: {}, // 选中行
                buttonList: [],
                searchVal: "",
                buttonListmsg: "",

                currentPage: 1, // 当前页码
                pageSize: 20, // 每页的数据条数
                total: null, // 总条数
                totaldata: null,

                loading: true,
                page: 1,
                dataList: [], // 列表数据

                value2: ''
            };
        },
        methods: {
            showSignin(index, rows) {
                console.log(rows, index)
                console.log(rows[index].Apply_QRCode)
                this.$refs.siginModal.operation = false; // 编辑
                this.$refs.siginModal.optType = false;   // 查看
                this.$refs.siginModal.onShowDlg(rows[index].Apply_QRCode);
            },
            callFunction(item) {
                this[item.Func].apply(this, item);
            },

            selectCurrentRow(val) {
                this.currentRow = val;
            },
            // 添加
            handleAdd() {
                this.$refs.checkupModal.operation = true; // 添加
                this.$refs.checkupModal.optType = true;   // 编辑
                this.$refs.checkupModal.onShowDlg();
            },
            // 查看
            handleShow() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要查看的一行数据！",
                        type: "error",
                    });
                    return;
                }

                this.$refs.checkupModal.operation = false; // 编辑
                this.$refs.checkupModal.optType = false;   // 查看
                //this.$refs.checkupModal.Apply_QRCode = row.Apply_QRCode;   // 查看
                this.$refs.checkupModal.onShowDlg(row.Id);
            },
            // 编辑
            handleEdit() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要查看的一行数据！",
                        type: "error",
                    });
                    return;
                }
                if (!row.bIsEdit) {
                    this.$message({
                        message: "数据不允许被编辑！",
                        type: "error",
                    });
                    return;
                }

                this.$refs.checkupModal.operation = false; // 编辑
                this.$refs.checkupModal.optType = true;    // 编辑
                this.$refs.checkupModal.onShowDlg(row.Id);
            },
            // 删除
            handleDelete() {
                let row = this.currentRow;
                if (!row) {
                    this.$message({
                        message: "请选择要删除的一行数据！",
                        type: "error",
                    });
                    return;
                }
                this.$confirm("确定要删除吗？", "提示", {}).then(() => {
                    let para = {
                        Id: row.Id,
                    };
                    DeleteTrainInfo(para).then((res) => {
                        if (res.data.success) {
                            this.$message({
                                message: "删除成功",
                                type: "success",
                                duration: 5000,
                            });
                            this.getData(false);
                        } else {
                            this.$message({
                                message: "删除失败",
                                type: "error",
                                duration: 5000,
                            });
                        }
                    });
                });
            },
            formatStartTime: function (row, colume) {
                var date = row[colume.property];
                if (date == undefined) {
                    return "";
                }
                return util.formatDate.format(new Date(date), "yyyy-MM-dd");
            },
            formatLecturer: function (row, colume) {
                var lecturer = "";
                for (var i = 0; i < row.Lecturers.length; i++) {
                    lecturer += row.Lecturers[i].UserName + ",";
                    
                }
                return lecturer

            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.page = val;
                this.getData(false);
            },

            //获取列表
            getData(isCurrent = true) {
                if (isCurrent) {
                    this.page = 1;
                }
                let para = {
                    intPageIndex: this.page,
                    intPageSize: this.pageSize,
                    appuserName: this.selectForm.appuserName,
                    appDpt: this.selectForm.appDpt,
                    ApplyLevel: this.selectForm.ApplyLevel
                };
                if (this.value2 != undefined && this.value2.length > 0) {
                    para.trainTimeStart = this.value2[0];
                    para.trainTimeEnd = this.value2[1];
                }
                
                GetTrainInfoPageList(para).then((res) => {
                    this.total = res.data.response.pageCount;
                    this.totaldata = res.data.response.dataCount;
                    this.dataList = res.data.response.data;
                    this.loading = false;
                });
            },
            getButtonList2(routers) {
                let _this = this;
                routers.forEach((element) => {
                    let path = this.$route.path.toLowerCase();
                    if (element.path && element.path.toLowerCase() == path) {
                        _this.buttonList = element.children;
                        return;
                    } else if (element.children) {
                        _this.getButtonList(element.children);
                    }
                });
            }
        },
        mounted() {
            this.getData();
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            let buttons = window.localStorage.buttList
                ? JSON.parse(window.localStorage.buttList)
                : [];
            this.buttonList = getButtonList(this.$route.path, routers, buttons);
        },
    };
</script>

<style scoped>
</style>